<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <div v-if="device" class="px-4 sm:px-0">
      <div class="relative mt-4 rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-4 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-ifgreen">
        <div class="flex-shrink-0">
          <router-link to="/production" class="focus:outline-none">
            <ArrowLeftIcon class="text-gray-900 h-6 w-6" />
          </router-link>
        </div>
        <div class="flex-1 min-w-0 border-l pl-4">
            <p class="text-sm font-medium text-gray-900">
              {{ device.parameters['numer_seryjny'] }}
            </p>
            <p class="text-sm text-gray-500 truncate">
              {{ stepSchema[device.deviceExtension.step].title }}
            </p>
        </div>
      </div>
      <div v-if="device && device.deviceExtension.step > 2" class="mt-6 bg-white shadow overflow-hidden rounded-md">
        <div class="border-t border-gray-200">
          <dl>
            <div  class="px-4 py-3 grid grid-cols-2 gap-4 px-6">
              <dt class="text-sm font-medium text-gray-500">
                Marka
              </dt>
              <dd class="text-sm font-medium text-gray-900 sm:mt-0 flex">
                <img :src="`/img/icons/${device.organisation.id}/favicon.ico`" class="h-5 w-5 mr-2" />
                {{ device.organisation.name }}
              </dd>
            </div>
          </dl>
        </div>
        <div v-for="param in deviceParams" :key="param.label" class="border-t border-gray-200">
          <dl>
            <div  class="px-4 py-3 grid grid-cols-2 gap-4 px-6" :class="param.value && param.value.slice(-1) === '*' && 'border border-yellow-600'">
              <dt class="text-sm font-medium text-gray-500">
                {{ param.label }}
              </dt>
              <dd class="text-sm text-gray-900 sm:mt-0 ">
                {{ param.value }} <ExclamationTriangleIcon v-if="param.value && param.value.slice(-1) === '*'" class="h-5 w-5 inline-block text-yellow-600" aria-hidden="true" />
              </dd>
            </div>
          </dl>
        </div>
        <div v-if="device.deviceExtension.tankNumber" class="border-t border-gray-200">
          <dl>
            <div class="px-4 py-3 grid grid-cols-2 gap-4 px-6">
              <dt class="text-sm font-medium text-gray-500">
                Numer zasobnika
              </dt>
              <dd class="text-sm text-gray-900 sm:mt-0 ">
                {{ device.tankNumber }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div v-if="device.deviceExtension.step === 8" class="mt-6 space-y-6">
        <ImageInput placeholder="Zdjęcie z lewej" id="left" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']"/>
        <ImageInput placeholder="Zdjęcie z prawej" id="right" v-model="images[1]" :watermark-text="device.parameters['numer_seryjny']"/>
        <ImageInput placeholder="Zdjęcie po zapakowaniu" id="packed" v-model="images[2]" :watermark-text="device.parameters['numer_seryjny']"/>
        <ImageInput placeholder="Zdjęcie kompletu zawartości opakowania" id="complet" v-model="images[3]" :watermark-text="device.parameters['numer_seryjny']"/>
      </div>
      <div v-if="substep !== null" class="mt-6">
        <LabeledSwitch
          v-for="(step, index) in stepSchema[device.deviceExtension.step].substeps"
          :key="index"
          :pkey="index" :title="step.title"
          :text="step.text ? step.text : null"
          :initial="index < substep"
          :disabled="index > substep || index < substep - 1"
          :active="index === substep"
          @change="changeStep"
        />
      </div>
      <div v-if="device.deviceExtension.step === 1" class="mt-4 relative z-10">
        <BarcodeScanner
          v-model="tankNumber"
          text="albo wpisz numer seryjny zasobnika"
          placeholder="numer seryjny zasobnika"
          :showEnterButton="false"
        />
      </div>
      <div v-if="specialDeviceTemplates && device.deviceExtension.step === 2" class="space-y-2">
        <div class="mt-2">
          <label class="block mb-1 text-sm font-medium text-gray-700">Marka urządzenia</label>
          <OrganisationSelect :global="false" @change="udpateOrganisation" />
        </div>
        <TemplateForm
          v-model="parameters"
          :schema="deviceTemplate.activeVersion.parametersSchema"
          :button="false"
        />
      </div>
      <div v-if="device.deviceExtension.step === 3" class="mt-6">
        <ImageInput placeholder="Zdjęcie hydrauliki" id="hydraulics" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']"/>
      </div>
      <div v-if="device.deviceExtension.step === 4" class="mt-6 space-y-6">
        <ImageInput placeholder="Zdjęcie manometrów 1" id="manometer" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']" />
        <ImageInput placeholder="Zdjęcie manometrów 2" id="manometer2" v-model="images[1]" :watermark-text="device.parameters['numer_seryjny']" />
        <ImageInput placeholder="Zdjęcie termometru" id="termometer" v-model="images[2]" :watermark-text="device.parameters['numer_seryjny']" />
      </div>
      <div v-if="device.deviceExtension.step === 5" class="mt-6 space-y-6">
        <ImageInput placeholder="Zdjęcie manometru CO" id="COmanometer" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']" />
        <ImageInput placeholder="Zdjęcie manometru CWU" id="CWUmanometer" v-model="images[1]" :watermark-text="device.parameters['numer_seryjny']" />
        <ImageInput placeholder="Zdjęcie szafy z założonymi wężami" id="mountedHoses" v-model="images[2]" :watermark-text="device.parameters['numer_seryjny']" />
      </div>
      <div v-if="device.deviceExtension.step === 6" class="mt-6 space-y-6">
        <ImageInput placeholder="Zdjęcie instalacji" id="electrics" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']" />
        <ImageInput placeholder="Zdjęcie instalacji 2" id="electrics2" v-model="images[1]" :watermark-text="device.parameters['numer_seryjny']" />
      </div>
      <div v-if="device.deviceExtension.step === 7" class="mt-6">
        <ImageInput placeholder="Zawartość opakowania" id="boxContent" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']" />
      </div>
      <div v-if="device.deviceExtension.step === 9" class="mt-6">
        <ImageInput placeholder="Zdjęcie etykiety" id="deliverylabel" v-model="images[0]" :watermark-text="device.parameters['numer_seryjny']" />
      </div>
      <div class="mt-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          Uwagi <ExclamationTriangleIcon v-if="device.parameters['wersja'] && device.parameters['wersja'].slice(-1) === '*'" class="h-5 w-5 inline-block text-yellow-600" aria-hidden="true" />
        </label>
        <div class="mt-1 relative">
          <textarea
            id="note"
            name="note"
            v-model="note"
            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
            @change="updateNote"
          />
        </div>
      </div>
      <div v-if="device.deviceExtension.step >= 2 && device.deviceExtension.step < 10" class="mt-4">
          <label for="worker" class="block text-sm font-medium text-gray-700">Etap wykonany przez</label>
          <select v-model="worker" id="worker" name="worker" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm rounded-md">
            <option value="" selected disabled>wybierz</option>
            <option v-for="(worker,index) in workers" :key="index" :value="worker">{{ worker }}</option>
          </select>
      </div>
      <button v-if="device.deviceExtension.step < 10" @click="confirmStage" type="button" :class="!confirmStageReady ? 'pointer-events-none opacity-60' : ''" class="my-6 inline-flex w-full items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen text-white bg-ifgreen hover:bg-ifgreen-dark">
        Zatwierdź etap
        <CheckCircleIcon class="-mr-1 ml-3 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import OrganisationSelect from '@/components/admin/OrganisationSelect.vue'
import TemplateForm from '@/components/admin/DeviceTemplate/TemplateForm.vue'
import LabeledSwitch from '@/components/inputs/LabeledSwitch.vue'
import ImageInput from '@/components/inputs/ImageInputNew.vue'
import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue'
import stepSchema from "@/assets/production-steps.json"
import { uploadFile, labelToCamelCase, deserializeParams } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'
import { api, patchHeaders } from "@/assets/js/api-client.js";

export default {
  name: "ProductionStep",
  components: {
    OrganisationSelect,
    ArrowLeftIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    LabeledSwitch,
    ImageInput,
    BarcodeScanner,
    TemplateForm
  },
  data () {
    return {
      organisation: process.env.VUE_APP_ORG_ID,
      substep: null,
      tankNumber: '',
      parameters: {},
      images: [],
      options: [],
      workers: [],
      worker: ""
    }
  },
  computed: {
     ...mapState(['device', 'specialDeviceTemplates']),
     stepSchema () {
      return stepSchema
    },
    deviceParams () {
      const schema = this.device.schemaVersion.parametersSchema
      return schema.map(field => {
        return {label: field.label, value: this.device.parameters[field.name]}
      }).filter(param => {return param.value})
    },
    deviceTemplate() {
      return this.specialDeviceTemplates.find(template => {return template.id == process.env.VUE_APP_IFDEVICE_TEMPLATE_ID})
    },
    confirmStageReady () {
      const substepsCompleted = this.substep >= this.stepSchema[this.device.deviceExtension.step].substeps.length
      if (this.device.step === 2) {
        return this.parameters.validated && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 3 ) {
        return substepsCompleted && this.images.length && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 4 ) {
        return substepsCompleted && this.images.length === 3 && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 5 ) {
        return substepsCompleted && this.images.length === 3 && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 6 ) {
        return substepsCompleted && this.images.length === 2 && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 7 ) {
        return substepsCompleted && this.images.length === 1 && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 8 ) {
        return substepsCompleted && this.images.length === 4 && this.worker !== ""
      } else if ( this.device.deviceExtension.step === 9 ) {
        return substepsCompleted && this.images.length === 1 && this.worker !== ""
      } else {
        return substepsCompleted
      }
    },
  },
  async created () {
    if (!this.device) {
      this.$store.dispatch('setLoading', true)
      try {
        const result = await api.get(`/custom_devices/${this.$route.params.id}`)
        console.log(result)
        this.$store.dispatch('setLoading', false)
        if (result.data) {
          this.$store.dispatch('saveDevice', result.data)
          this.substep = this.device.deviceExtension.substep
          this.note = this.device.deviceExtension.note
          this.parameters = this.device.parameters
        }
      } catch (err) {
        this.$store.dispatch('setLoading', false)
        if (err.response.data.detail == 'Not Found') {
          this.$router.push({name: 'production'})
        } else {
          this.$notify({
            title: 'Podczas próby połączenia z bazą danych wystąpił błąd:',
            text: err.response.data.detail,
            type: 'error',
            duration: 15000
          })
        }
      }
    }
    else {
      this.note = this.device.deviceExtension.note
      this.parameters = this.device.parameters
    }

    if (this.device.deviceExtension.step >= 2 && this.device.deviceExtension.step < 10){
      this.$store.dispatch('setLoading', true)
      api.get('/production_steps').then(result => {
        this.$store.dispatch('setLoading', false)
        const currentStep = result.data.find(step => {return step.stepNumber === this.device.deviceExtension.step})
        this.workers = currentStep.workers
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        console.log(err)
      })
    }
  },
  mounted () {
    if (this.device) this.substep = this.device.deviceExtension.substep
  },
  methods: {
    udpateOrganisation (id) {
      console.log(id)
      this.organisation = id
    },
    updateNote () {
      const device = {
        deviceExtension: {
          note: this.note
        }
      }

      this.updateDevice(device)
    },
    changeStep (e) {
      this.substep = e.state ? this.substep + 1 : this.substep - 1
      const device = {
        deviceExtension: {
          substep: this.substep
        }
      }
      this.updateDevice(device)
    },
    confirmStage () {
      console.log(this.device.deviceExtension.step)
      this.$store.dispatch('setLoading', true)
      const device = {
        deviceExtension: {
          step: this.device.deviceExtension.step + 1
        }
      }
      let files

      switch (this.device.deviceExtension.step) {
        case 1:
          device.deviceExtension["tankNumber"] = this.tankNumber
          this.confirmStageApiCall(device);
          break;
        case 2:
          device['parameters'] = this.parameters
          delete device.parameters.validated
          if (this.deviceTemplate.organisation.id != this.organisation) {
            const template = this.specialDeviceTemplates.find(template => {return template.organisation.id == this.organisation})
            device['organisation'] = `/organisations/${this.organisation}`
            device['deviceTemplate'] = `/device_templates/${template.id}`
            device['schemaVersion'] = `/device_template_schema_versions/${template.activeVersion.id}`
          } else {
            device['schemaVersion'] = `/device_template_schema_versions/${this.deviceTemplate.activeVersion.id}`
          }
          this.confirmStageApiCall(device)
          break;
        case 3:
          files = [{label: 'hydraulics', type: 'image/jpeg', file: this.images[0].file, base64: this.images[0].file.split(',')[1]}]
          this.sendFiles(files, this.confirmStageApiCall, device)
          break;
        case 4:
          files = [
            {label: 'manometers', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
            {label: 'manometers-2', type: 'image/jpeg', base64: this.images[1].file.split(',')[1]},
            {label: 'termometer', type: 'image/jpeg', base64: this.images[2].file.split(',')[1]}
          ]
          this.sendFiles(files, this.confirmStageApiCall, device)
          break;
        case 5:
          files = [
            {label: 'heating-manometer', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
            {label: 'dhw-manometer', type: 'image/jpeg', base64: this.images[1].file.split(',')[1]},
            {label: 'hoses-installed', type: 'image/jpeg', base64: this.images[2].file.split(',')[1]}
          ]
          this.sendFiles(files, this.confirmStageApiCall, device)
          break;
        case 6:
          files = [
            {label: 'electrics', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
            {label: 'electrics-2', type: 'image/jpeg', base64: this.images[1].file.split(',')[1]}
          ]
          this.sendFiles(files, this.confirmStageApiCall, device)
          break;
        case 7:
          files = [
            {label: 'box-content', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
          ]
          this.sendFiles (files, this.confirmStageApiCall, device)
          break;
        case 8:
          files = [
            {label: 'from-left', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
            {label: 'from-right', type: 'image/jpeg', base64: this.images[1].file.split(',')[1]},
            {label: 'packed', type: 'image/jpeg', base64: this.images[2].file.split(',')[1]},
            {label: 'complet', type: 'image/jpeg', base64: this.images[3].file.split(',')[1]}
          ]
          this.sendFiles (files, this.confirmStageApiCall, device)
          break;
        case 9:
          files = [
            {label: 'delivery-label', type: 'image/jpeg', base64: this.images[0].file.split(',')[1]},
          ]
          this.sendFiles (files, this.confirmStageApiCall, device)
          break;
        default:
          this.confirmStageApiCall(device);
          break;
      }
    },
    sendFiles (files, callback, device) {

      const filesRequestData = files.map((file) => {
        return {
          filename: `${this.device.id}-${file.label}.${file.type === 'image/jpeg' ? 'jpg' : 'pdf'}`,
          filetype: file.type
        }
      })

      api.post('/get-s3-urls', filesRequestData).then((result) => {
        const uploads = result.data.urls.map((url, index) => {
          const key = `${labelToCamelCase(files[index].label)}${files[index].type === 'image/jpeg' ? 'Image' : ''}`
          device.deviceExtension[key] = url.split('?')[0]
          return uploadFile(url, files[index].base64, files[index].type)
        })

        Promise.all(uploads).then(() => {
          console.log("Files uploaded")
          callback(device)
        })
      })
    },
    confirmStageApiCall (device) {
      device.deviceExtension['lastActingWorker'] = this.worker
      device.deviceExtension['step'] = this.device.deviceExtension.step + 1
      device.deviceExtension['substep'] = 0
      console.log(device)
      const callback = () => {
        this.$notify({
          title: "Sukces!",
          text: `Etap numer ${this.device.deviceExtension.step} dla urządzenia o numerze seryjnym ${this.device.parameters['numer_seryjny']} został pomyślnie zakończony.`,
          type: 'success'
        })
        this.$router.push({path: '/production'})
      }
      this.updateDevice(device, callback)
    },
    updateDevice (device, callback = null) {
      device = deserializeParams(device)
      api.patch(`/custom_devices/${this.device.id}`, device, patchHeaders).then(result => {
        console.log(result)
        if (typeof callback === 'function') {
          callback()
        }
      }).catch(err => {
        console.log(err)
        this.$notify({
          title: "Podczas próby połączenia z bazą danych wystąpił bład:",
          text: err.response.data.detail,
          type: 'error',
          duration: 15000
        })
      })
    }
  }
};
</script>

<style scoped lang="scss">
  .min-full-screen{
    min-height: calc(100vh - 124px);
  }
  .checked-full-vh{
    height: calc(100vh - var(--vh-offset, 0px));
  }
</style>
